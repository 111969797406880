exports.components = {
  "component---src-zakat-pages-app-tsx": () => import("./../../../src/Zakat/pages/app.tsx" /* webpackChunkName: "component---src-zakat-pages-app-tsx" */),
  "component---src-zakat-pages-auth-[page]-tsx": () => import("./../../../src/Zakat/pages/auth/[page].tsx" /* webpackChunkName: "component---src-zakat-pages-auth-[page]-tsx" */),
  "component---src-zakat-pages-auth-index-tsx": () => import("./../../../src/Zakat/pages/auth/index.tsx" /* webpackChunkName: "component---src-zakat-pages-auth-index-tsx" */),
  "component---src-zakat-pages-charity-allocation-charities-logos-tsx": () => import("./../../../src/Zakat/pages/charity-allocation/charitiesLogos.tsx" /* webpackChunkName: "component---src-zakat-pages-charity-allocation-charities-logos-tsx" */),
  "component---src-zakat-pages-charity-allocation-charities-pie-chart-tsx": () => import("./../../../src/Zakat/pages/charity-allocation/charitiesPieChart.tsx" /* webpackChunkName: "component---src-zakat-pages-charity-allocation-charities-pie-chart-tsx" */),
  "component---src-zakat-pages-charity-allocation-helpers-ts": () => import("./../../../src/Zakat/pages/charity-allocation/helpers.ts" /* webpackChunkName: "component---src-zakat-pages-charity-allocation-helpers-ts" */),
  "component---src-zakat-pages-charity-allocation-index-tsx": () => import("./../../../src/Zakat/pages/charity-allocation/index.tsx" /* webpackChunkName: "component---src-zakat-pages-charity-allocation-index-tsx" */),
  "component---src-zakat-pages-charity-allocation-styles-tsx": () => import("./../../../src/Zakat/pages/charity-allocation/styles.tsx" /* webpackChunkName: "component---src-zakat-pages-charity-allocation-styles-tsx" */),
  "component---src-zakat-pages-giftaid-data-ts": () => import("./../../../src/Zakat/pages/giftaid/data.ts" /* webpackChunkName: "component---src-zakat-pages-giftaid-data-ts" */),
  "component---src-zakat-pages-giftaid-giftaid-style-ts": () => import("./../../../src/Zakat/pages/giftaid/giftaid.style.ts" /* webpackChunkName: "component---src-zakat-pages-giftaid-giftaid-style-ts" */),
  "component---src-zakat-pages-giftaid-zakat-gift-aid-page-tsx": () => import("./../../../src/Zakat/pages/giftaid/ZakatGiftAidPage.tsx" /* webpackChunkName: "component---src-zakat-pages-giftaid-zakat-gift-aid-page-tsx" */),
  "component---src-zakat-pages-index-tsx": () => import("./../../../src/Zakat/pages/index.tsx" /* webpackChunkName: "component---src-zakat-pages-index-tsx" */),
  "component---src-zakat-pages-payment-index-tsx": () => import("./../../../src/Zakat/pages/payment/index.tsx" /* webpackChunkName: "component---src-zakat-pages-payment-index-tsx" */),
  "component---src-zakat-pages-payment-styles-payment-style-tsx": () => import("./../../../src/Zakat/pages/payment/styles/payment.style.tsx" /* webpackChunkName: "component---src-zakat-pages-payment-styles-payment-style-tsx" */),
  "component---src-zakat-pages-referral-index-tsx": () => import("./../../../src/Zakat/pages/referral/index.tsx" /* webpackChunkName: "component---src-zakat-pages-referral-index-tsx" */),
  "component---src-zakat-pages-summary-index-tsx": () => import("./../../../src/Zakat/pages/summary/index.tsx" /* webpackChunkName: "component---src-zakat-pages-summary-index-tsx" */),
  "component---src-zakat-pages-summary-styles-summary-style-tsx": () => import("./../../../src/Zakat/pages/summary/styles/summary.style.tsx" /* webpackChunkName: "component---src-zakat-pages-summary-styles-summary-style-tsx" */),
  "component---src-zakat-pages-summary-styles-topup-style-tsx": () => import("./../../../src/Zakat/pages/summary/styles/topup.style.tsx" /* webpackChunkName: "component---src-zakat-pages-summary-styles-topup-style-tsx" */),
  "component---src-zakat-pages-summary-zakat-breakdown-tsx": () => import("./../../../src/Zakat/pages/summary/zakatBreakdown.tsx" /* webpackChunkName: "component---src-zakat-pages-summary-zakat-breakdown-tsx" */),
  "component---src-zakat-pages-summary-zakat-summary-page-provider-tsx": () => import("./../../../src/Zakat/pages/summary/ZakatSummaryPageProvider.tsx" /* webpackChunkName: "component---src-zakat-pages-summary-zakat-summary-page-provider-tsx" */),
  "component---src-zakat-pages-top-up-index-tsx": () => import("./../../../src/Zakat/pages/top-up/index.tsx" /* webpackChunkName: "component---src-zakat-pages-top-up-index-tsx" */)
}

